<template>
  <v-col cols="12">
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="$emit('onCreateNew', model)">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>

      <slot />
    </v-card>
  </v-col>
</template>

<script>
export default {
  name: 'BaseOverviewCard',

  props: {
    model: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
